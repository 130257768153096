import { BrowserRouter, Routes, Route } from "react-router-dom";
import { default as Portfolio } from './portfolio/App';
import DogServices from "./dogServices/App";
import { RampApplicationChallenge } from "./rampApplicationChallenge";
import { Valentines } from "./valentines";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route exact path='/' element={< Portfolio />}></Route>
          <Route exact path='/dogServices' element={< DogServices />}></Route>
          <Route exact path='/rampApplicationChallenge' element={< RampApplicationChallenge />}></Route>
          <Route exact path='/valentines' element={< Valentines />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
